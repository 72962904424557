import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import ScreenHome from './pages/home'
import 'bootstrap/dist/css/bootstrap.min.css'
import ErrorBoundary from './pages/ErrorBoundary'
import {APPCONFIG} from './app-config'

const loader = async ({request}) => {
  const params = new URLSearchParams(window.location.search)
  const live = params.get('live') ?? 1
  try {
    const response = await fetch(
      `${APPCONFIG.apiUrl}${APPCONFIG.API.gameTimer}${live > 1 ? 0 : live}/`,
      {
        signal: request.signal,
        headers: {
          'ngrok-skip-browser-warning': true,
        },
      },
    )

    if (response.status !== 200) {
      return response
    }

    return response?.json()
  } catch (e) {
    throw new Response('Not Found', {status: 404})
  }
}

const router = createBrowserRouter([
  {
    path: '/',
    loader,
    errorElement: <ErrorBoundary />,
    element: (
      <div className="App">
        <ScreenHome />
      </div>
    ),
  },
])
function App() {
  return <RouterProvider router={router} />
}

export default App
