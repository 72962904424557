export const APPCONFIG = {
  videoWsUrl:
    'wss://huthut-sport-radar--40894.botics.co/ws/sync-video-socket-web/',
  videoUrls: [
    'https://www.youtube.com/watch?v=yx9VaOXHxpA&pp=ygUOaG9yc2VzIHJ1bm5pbmc%3D',
  ],
  apiUrl: 'https://huthut-sport-radar--40894.botics.co',
  API: {
    gameTimer: '/game/game-timing-json/',
  },
}
