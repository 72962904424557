export const TIME_STATS = {
  0: {
    status: 'i',
    play: 1,
  },
  37: {
    status: 'p',
    play: 1,
  },
  43: {
    status: 'g',
    play: 1,
  },
  48: {
    status: 'r',
    play: 1,
  },

  49: {
    status: 'i',
    play: 2,
  },
  71: {
    status: 'p',
    play: 2,
  },
  76: {
    status: 'g',
    play: 2,
  },
  87: {
    status: 'r',
    play: 2,
  },

  88: {
    status: 'i',
    play: 3,
  },
  106: {
    status: 'p',
    play: 3,
  },
  112: {
    status: 'g',
    play: 3,
  },
  119: {
    status: 'r',
    play: 3,
  },

  120: {
    status: 'i',
    play: 4,
  },
  146: {
    status: 'p',
    play: 4,
  },
  151: {
    status: 'g',
    play: 4,
  },
  160: {
    status: 'r',
    play: 4,
  },

  161: {
    status: 'i',
    play: 5,
  },

  200: {
    status: 't',
    play: 5,
  },

  241: {
    status: 'i',
    play: 5,
  },
  261: {
    status: 'p',
    play: 5,
  },
  267: {
    status: 'g',
    play: 5,
  },
  274: {
    status: 'r',
    play: 5,
  },

  275: {
    status: 'i',
    play: 6,
  },
  289: {
    status: 'p',
    play: 6,
  },
  295: {
    status: 'g',
    play: 6,
  },
  303: {
    status: 'r',
    play: 6,
  },

  304: {
    status: 'i',
    play: 7,
  },
  331: {
    status: 'p',
    play: 7,
  },
  336: {
    status: 'g',
    play: 7,
  },
  349: {
    status: 'r',
    play: 7,
  },

  350: {
    status: 'i',
    play: 8,
  },
  381: {
    status: 'p',
    play: 8,
  },
  387: {
    status: 'g',
    play: 8,
  },
  390: {
    status: 'r',
    play: 8,
  },

  391: {
    status: 'i',
    play: 9,
  },
  417: {
    status: 'p',
    play: 9,
  },
  424: {
    status: 'g',
    play: 9,
  },
  433: {
    status: 'r',
    play: 9,
  },

  434: {
    status: 'i',
    play: 10,
  },
  451: {
    status: 'p',
    play: 10,
  },
  457: {
    status: 'g',
    play: 10,
  },
  464: {
    status: 'r',
    play: 10,
  },

  465: {
    status: 'i',
    play: 11,
  },
  479: {
    status: 'p',
    play: 11,
  },
  484: {
    status: 'g',
    play: 11,
  },
  490: {
    status: 'r',
    play: 11,
  },

  491: {
    status: 'i',
    play: 12,
  },
  523: {
    status: 'p',
    play: 12,
  },
  528: {
    status: 'g',
    play: 12,
  },
  535: {
    status: 'r',
    play: 12,
  },

  536: {
    status: 'i',
    play: 13,
  },
  583: {
    status: 'p',
    play: 13,
  },
  588: {
    status: 'g',
    play: 13,
  },
  594: {
    status: 'r',
    play: 13,
  },

  595: {
    status: 'ad',
  },

  613: {
    status: 'i',
    play: 14,
  },
  622: {
    status: 'p',
    play: 14,
  },
  627: {
    status: 'g',
    play: 14,
  },
  636: {
    status: 'r',
    play: 14,
  },

  637: {
    status: 'i',
    play: 15,
  },
  679: {
    status: 'p',
    play: 15,
  },
  685: {
    status: 'g',
    play: 15,
  },
  693: {
    status: 'r',
    play: 15,
  },

  694: {
    status: 'i',
    play: 16,
  },
  723: {
    status: 'p',
    play: 16,
  },
  728: {
    status: 'g',
    play: 16,
  },
  740: {
    status: 'r',
    play: 16,
  },

  741: {
    status: 'i',
    play: 17,
  },
  765: {
    status: 'p',
    play: 17,
  },
  770: {
    status: 'g',
    play: 17,
  },
  779: {
    status: 'r',
    play: 17,
  },

  780: {
    status: 'i',
    play: 18,
  },
  808: {
    status: 'p',
    play: 18,
  },
  813: {
    status: 'g',
    play: 18,
  },
  821: {
    status: 'r',
    play: 18,
  },

  822: {
    status: 'i',
    play: 19,
  },

  833: {
    status: 'ad',
  },

  863: {
    status: 'i',
    play: 19,
  },
  890: {
    status: 'p',
    play: 19,
  },
  896: {
    status: 'g',
    play: 19,
  },
  904: {
    status: 'r',
    play: 19,
  },

  905: {
    status: 'i',
    play: 20,
  },
  937: {
    status: 'p',
    play: 20,
  },
  942: {
    status: 'g',
    play: 20,
  },
  947: {
    status: 'r',
    play: 20,
  },

  948: {
    status: 'i',
    play: 21,
  },
  978: {
    status: 'p',
    play: 21,
  },
  983: {
    status: 'g',
    play: 21,
  },
  997: {
    status: 'r',
    play: 21,
    game_end: 1,
  },
}
