import {Spinner} from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

export function ModalPopup(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Reset </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <h4>Reset</h4> */}
        <p>
          Are you sure you want to Reset? This will erase all users from server.
        </p>
      </Modal.Body>
      <Modal.Footer className="justify-content-center gap-4">
        <Button onClick={props.onHide} disabled={props.loading}>
          Cancel
        </Button>

        <Button variant="danger" disabled={props.loading} onClick={props.onClickOk}>
          {props.loading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Proceed
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
