import React, {useCallback, useState} from 'react'
import {Alert, Button, Col, Row, Spinner} from 'react-bootstrap'
import {useDropzone} from 'react-dropzone'
import {reset} from '../../utils/common'
import {ModalPopup} from '../../components/ModalPopup'

const FileSelector = ({selectFile, success, setSuccess, error, setError}) => {
  const [loading, setLoading] = useState(false)
  // const [success, setSuccess] = useState(false)
  // const [error, setError] = useState(false)
  const [show, setShow] = useState(false)
  const onHide = () => setShow(false)

  const onDrop = useCallback(
    acceptedFiles => {
      acceptedFiles?.length && selectFile(URL.createObjectURL(acceptedFiles[0]))
    },
    [selectFile],
  )

  const {acceptedFiles, fileRejections, getRootProps, getInputProps} =
    useDropzone({
      maxFiles: 1,
      accept: {
        'video/mp4': ['.mp4'],
        'video/mpeg': ['.mpeg'],
        'video/webm': ['.webm'],
        'video/ogg': ['.ogv', '.ogg'],
      },
      onDrop,
    })

  const acceptedFileItems = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ))

  const fileRejectionItems = fileRejections.map(({file, errors}) => {
    return (
      <li key={file.path}>
        {file.path} - {file.size} bytes
        <ul>
          {errors.map(e => (
            <li key={e.code}>{e.message}</li>
          ))}
        </ul>
      </li>
    )
  })

  const onClickReset = () => {
    setLoading(true)
    reset()
      .then(res => {
        onHide()
        setLoading(false)
        setSuccess(true)
      })
      .catch(() => {
        setLoading(false)
        setError(true)
      })
  }

  return (
    <section className="file-upload-container flex-column">
      <div
        {...getRootProps({className: 'dropzone'})}
        className="drop-zone-container">
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
        <p className="help-text">
          Supported file extensions: .mp4, .mpeg, .webm, .ogv, .ogg
        </p>
      </div>
      <aside>
        {Boolean(acceptedFileItems?.length) && (
          <>
            <h4>Accepted files</h4>
            <ul>{acceptedFileItems}</ul>
          </>
        )}
        {Boolean(fileRejectionItems?.length) && (
          <>
            <h4>Rejected files</h4>
            <ul>{fileRejectionItems}</ul>
          </>
        )}
      </aside>
      <Row className="flex-column">
        <Col className="text-center">
          <Button
            variant="danger"
            onClick={() => setShow(true)}
            style={{position: 'absolute', bottom: '5rem'}}>
            Reset
          </Button>
        </Col>
        <Col>
          {success && (
            <Alert key="success" variant="success">
              Server reset successful. Please reset Mobile apps to start again.
            </Alert>
          )}
          {error && (
            <Alert key="danger" variant="danger">
              An error occurred while resetting servers. Please try again.
            </Alert>
          )}
        </Col>
      </Row>
      <ModalPopup
        show={show}
        onHide={onHide}
        onClickOk={onClickReset}
        loading={loading}
      />
    </section>
  )
}

export default FileSelector
