import React, {useEffect, useRef, useState} from 'react'
import {useLoaderData} from 'react-router-dom'
import useWebSocket, {ReadyState} from 'react-use-websocket'
import ReactPlayer from 'react-player'
import {reset, useEffectOnce} from '../../utils/common'
import {APPCONFIG} from '../../app-config'
import FileSelector from './FileSelector'
import {Button, Col, Row} from 'react-bootstrap'
import {ModalPopup} from '../../components/ModalPopup'
import {TIME_STATS} from '../../utils/constant'
import './home.scss'

const ScreenHome = () => {
  const timeStats = useLoaderData() || TIME_STATS

  const [videoInUse, setVideoUrl] = useState('')
  const refVideoPlayer = useRef()
  const [videoLoaded, setVideoLoaded] = useState(false)
  const [videoIsPlaying, setVideoIsPlaying] = useState(false)
  const [messageToSend, setMessageToSend] = useState('')

  const [show, setShow] = useState(false)
  const onHide = () => setShow(false)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const onClickOk = () => {
    setLoading(true)
    reset()
      .then(res => {
        onHide()
        setVideoUrl('')
        setLoading(false)
        setSuccess(true)
      })
      .catch(() => {
        setLoading(false)
        setError(true)
      })
  }

  const {sendJsonMessage, lastMessage, readyState} = useWebSocket(
    APPCONFIG.videoWsUrl,
    {
      shouldReconnect: CloseEvent => {
        console.log(CloseEvent)
        return true
      },
      share: true,
    },
  )

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState]

  useEffect(() => {
    console.log({connectionStatus})
  }, [connectionStatus])

  useEffect(() => {
    if (lastMessage !== null) {
      console.log('Last message: ', JSON.parse(lastMessage?.data || ''))
    }
  }, [lastMessage])

  useEffectOnce(() => {
    console.log('useEffectOnce')
    if (!!refVideoPlayer.current?.seekTo) refVideoPlayer.current.seekTo(5)
  }, [])

  useEffect(() => {
    if (!!videoLoaded) {
      console.log('LOADED Start playing...')
      setVideoIsPlaying(true)
      setMessageToSend(timeStats[0])
    }
  }, [videoLoaded])

  useEffect(() => {
    if (videoIsPlaying && messageToSend) {
      console.log('Message to send', messageToSend)
      sendJsonMessage(messageToSend, true)
    }

    if (!videoIsPlaying && messageToSend) {
      console.log('Message to send', {
        status: 'paused',
      })
      sendJsonMessage(
        {
          status: 'paused',
        },
        true,
      )
    }
  }, [messageToSend, videoIsPlaying])

  const onProgressReactVideo = progress => {
    console.log(Math.floor(progress?.playedSeconds), 'sec')
    const playedSeconds = Math.floor(progress?.playedSeconds)
    if (timeStats[playedSeconds]) {
      setMessageToSend(timeStats[playedSeconds])
    }
  }

  const onPause = () => {
    setVideoIsPlaying(false)
  }

  return (
    <div className="">
      {videoInUse ? (
        <Row className="video-in-use-wrapper">
          <Col xs="1">
            <Button
              variant="outline-primary"
              className="change-video"
              onClick={() => {
                setVideoUrl('')
                setSuccess(false)
              }}
              style={{zIndex: '999'}}>
              Select New File
            </Button>
          </Col>
          <Col xs="10" className="position-relative react-player-wrapper">
            <ReactPlayer
              ref={refVideoPlayer}
              url={videoInUse}
              playing={videoIsPlaying}
              volume="0.5"
              controls
              muted
              playsinline
              onReady={() => {
                setVideoLoaded(true)
              }}
              onPause={onPause}
              onPlay={() => {
                setVideoIsPlaying(true)
              }}
              onProgress={onProgressReactVideo}
              onStart={() => {
                setVideoIsPlaying(true)
              }}
              onEnded={() => {
                setVideoIsPlaying(false)
              }}
            />
          </Col>
          <Col xs="1">
            <Button variant="danger" onClick={() => setShow(true)}>
              Reset
            </Button>
          </Col>
        </Row>
      ) : (
        <FileSelector
          selectFile={setVideoUrl}
          success={success}
          setSuccess={setSuccess}
          error={error}
          setError={setError}
        />
      )}
      <ModalPopup
        show={show}
        onHide={onHide}
        onClickOk={onClickOk}
        loading={loading}
      />
    </div>
  )
}
export default ScreenHome
